<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  border: 0;
}

#app {
  background-color: #ffffff; /* 您可以选择任何您喜欢的颜色 */
  min-height: 100vh; /* 确保背景色填充整个视窗高度 */
}
</style>