import Vue from 'vue';

import VueI18n from 'vue-i18n';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import App from './App.vue';
import router from './router'

Vue.config.productionTip = false

Vue.use(VueI18n);
Vue.use(ElementUI);

const messages = {
    en: {
        welcome: 'Welcome',
        message: 'This is the English version of the website.'
    },
    cn: {
        welcome: '欢迎',
        message: '这是网站的中文版本。'
    }
};

const i18n = new VueI18n({
    locale: 'en', // 默认语言
    messages, // 语言包内容
});

new Vue({
    router,
    i18n,
    el: '#app',
    render: h => h(App),
}).$mount('#app');