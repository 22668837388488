import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

export default new Router({
    mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/HomePage.vue')
    },
    {
        path: '/background',
        name: 'background',
        component: () => import('@/components/BackgroundView.vue')
    }
  ]
});
